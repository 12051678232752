import React, { Fragment } from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { renderIcon } from '../utils/helpers.js'
import Showdown from 'showdown'

function groupFeatures(data, type) {
  switch(type) {
    case 'legacy':
      return data.legacy;
    case 'server':
      return data.server;
    default:
      return data.feature;
  }
}

class Compatibility extends React.Component {
  render() {
    const converter = new Showdown.Converter()
    const osHeader =  
        this.props.type === "current" ? ["", "W7/8", "W7/8 64", "2008", "2011", "2012", "W10", "2016", "2019", "W11"] : 
        this.props.type === "legacy" ? ["", "W7", "W7 64", "Vista", "Vista 64", "2003", "XP", "XP 64", "2000"] : 
        ["", "2008", "2011"]

    return (
      <div className="container-fluid compatibility-container" >
      {this.props.data.map((group, index) => (
        <section className="py-1" key={index}>
          <MDBRow className="compatibility-group text-center">
            <MDBCol>{group.title}</MDBCol>
          </MDBRow>
          <MDBRow className="compatibility-header">
            {osHeader.map((osTitle, index) => (
            <MDBCol className={`text-center col-${index === 0 ? Math.min((13 - osHeader.length), 4).toString() : "1"}`} key={index}>{osTitle}</MDBCol>
            ))}
          </MDBRow>

          {groupFeatures(group, this.props.type).map((matrix, index) => (
            <MDBRow className="compatibility-row" key={index}>
              <MDBCol className={`text-small font-w-700 col-${Math.min((13 - osHeader.length), 4).toString()}`}>
                {matrix.title}
                <div
                  className="font-w-400 text-small divlink pt-1"
                  dangerouslySetInnerHTML={{ __html: converter.makeHtml(matrix.description) }}
                />
              </MDBCol>
            
              {osHeader.map((randomName, index) => ( 
                <Fragment key={index}>
                  {index > 0 &&  
                    <MDBCol className={`text-center col-${index === 0 ? Math.min((13 - osHeader.length), 4).toString() : "1"}`}>{renderIcon(matrix[Object.keys(matrix)[index+1]])}</MDBCol>
                  }
                </Fragment>
              ))}
            </MDBRow>
          ))}
        </section>
      ))}
      </div>
    )
  }
}

export default Compatibility